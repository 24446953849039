<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
            ref="addNewUserForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="addNewUser"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="FullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Username"
              label-for="Email"
            >
              <b-form-input
                id="Email"
                v-model="Email"
                type="text"
      @blur="validateEmail"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone Number -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="PhoneNumber"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="RoleId"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="RoleId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roles"
                :clearable="false"
                @input="changeRoute"
                input-id="RoleId"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Departement -->
          <validation-provider
          v-if="isDepartementShown==true"
            #default="validationContext"
            name="User Departement"
          >
            <b-form-group
              label="User Departement"
              label-for="DepartementID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="DepartementID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departements"
                :reduce="val => val.value"
                :clearable="false"
                input-id="DepartementID"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Branch -->
          <validation-provider
            #default="validationContext"
            name="User Branch"
            v-if="isShown == true"
          >
            <b-form-group
              label="User Branch"
              label-for="BranchID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="BranchID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="BranchID"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Region -->
          <validation-provider
            #default="validationContext"
            name="Branch Region"
            v-if="isShownRegion==true"
          >
            <b-form-group
              label="Regions"
              label-for="RegionID"
              :state="getValidationState(validationContext)"
            >
              <v-select-new
                v-model="RegionIDs"
                name="RegionIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regions"
                :clearable="false"
                input-id="RegionIDs"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Occupation -->
          <validation-provider
            #default="validationContext"
            name="Occupation"
            rules="required"
          >
            <b-form-group
              label="Occupation"
              label-for="Occupation"
            >
              <b-form-input
                id="Occupation"
                v-model="Occupation"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Occupation"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
                block
                :disabled="submitButtonDisabled"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import moment from 'moment'
import { ref } from '@vue/composition-api'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useUsersList from './useUsersList'

const Email = ''
const Password = ''
const ConfirmPassword = ''
const FullName = ''
const PhoneNumber = ''
const Gender = ''
const RoleID = ''
const BranchID = ''
const DepartementID = ''
const Occupation = ''
const isShown = false
const isDepartementShown = false
const regions = []
const RegionIDs = []
const isShownRegion = false
const submitButtonDisabled = false
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitButtonDisabled,
      isShownRegion,
      BranchID,
      regions,
      RegionIDs,
      Occupation,
      isShown,
      required,
      alphaNum,
      roles: [],
      branches: [],
      Email,
      Password,
      ConfirmPassword,
      FullName,
      PhoneNumber,
      Gender,
      RoleID,
      DepartementID,
      isValidEmail: true ,
      isDepartementShown,
    }
  },
  setup() {
    const blankUserData = {
      FullName: '',
      Email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const genders = [
          { label: 'Male', value: 'L' },
          { label: 'Female', value: 'P' },
        ]
    const userData = ref(blankUserData)
    const resetuserData = () => {
      userData.value = blankUserData
    }

    const onSubmit = () => {
          const userToken = this.$cookies.get('userToken')
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
      axios
          .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`, { headers })
          .then(response => {
                const branchesJoin = response.data.data.map(elem => (
                { label: `(${elem.branch_code}) ${elem.branch_address}`, value: elem.branch_id, kodeunit: elem.branch_code }
                ))
                this.branches = branchesJoin.sort((a, b) => parseInt(a.kodeunit) > parseInt(b.kodeunit) ? 1 : -1)
          })
          .catch(err => {
              console.log(err)
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      genders,
    }
  },
  mounted() {
      this.getRoles()
      this.getBranches()
      this.getDepartements()
      this.getRegions()
  },
  methods: {
    getRegions() {
          const userToken = this.$cookies.get('userToken')
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_REGION}`, { headers })
              .then(response => {
                const regionsJoin = []
                response.data.data.map(elem => {
                  regionsJoin.push({ label: elem.region_name, value: elem.region_id, slug: elem.region_slug })
                })
                this.regions = regionsJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getBranches() {
          const userToken = this.$cookies.get('userToken')
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
          axios
          .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`, { headers })
              .then(response => {
                const branchesJoin = []
                response.data.data.map(elem => {
                  branchesJoin.push({ label: `(${elem.branch_code}) ${elem.branch_address}`, value: elem.branch_id, kodeunit: elem.branch_code })
                })
                this.branches = branchesJoin.sort((a, b) => parseInt(a.kodeunit) > parseInt(b.kodeunit) ? 1 : -1)
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getRoles() {
          const userToken = this.$cookies.get('userToken')
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ROLES}`, { headers })
              .then(response => {
                const rolesJoin = []
                response.data.data.map(elem => {
                    rolesJoin.push({ label: elem.role_name, value: elem.role_id, slug: elem.role_slug })
                })
                this.roles = rolesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getDepartements() {
          const userToken = this.$cookies.get('userToken')
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DEPARTEMENTS}`, { headers })
              .then(response => {
                const rolesJoin = []
                response.data.data.map(elem => {
                    rolesJoin.push({ label: elem.departement_name, value: elem.departement_id })
                })
                this.departements = rolesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      changeRoute() {
        if (this.RoleId.slug === 'user' || this.RoleId.slug === 'stock-manager' || this.RoleId.slug === 'purchasing-cabang' || this.RoleId.slug === 'laboratory-manager' || this.RoleId.slug === 'supervisor-cabang' || this.RoleId.slug === 'warehouse-cabang' || this.RoleId.slug === 'finance') {
          this.isShown = true
        } else {
          this.isShown = false
        }
        if (this.RoleId.slug === 'user' || this.RoleId.slug === 'supervisor-cabang') {
          this.isDepartementShown = true
        }
        if (this.RoleId.slug === 'regional-manager') {
          this.isShownRegion = true
        } else {
          this.isShownRegion = false
        }
      },
    addNewUser() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      }
      this.$refs.addNewUserForm.validate().then(success => {
        const payload = {
          user_username: this.Email,
          user_fullname: this.FullName,
          user_phone: this.PhoneNumber,
          user_role_id: this.RoleId.value,
          user_departement_id: this.DepartementID,
          user_branch_id: this.BranchID,
          user_occupation: this.Occupation,
          regions: this.RegionIDs,
          time: moment(),
          }
          console.log("Departement : ", this.DepartementID)
          console.log("DEPT : ",this.DepartementID)
          console.log(payload)
        if (success) {
          axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADD_USER}`, payload, { headers })
        .then(response => {
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              if (response.data.success === true) {
              this.submitButtonDisabled = false
              this.Email = ""
              this.FullName = ""
              this.PhoneNumber = ""
              this.RoleId = ""
              this.DepartementID = ""
              this.BranchID = ""
              this.Occupation = ""
              this.RegionIDs = []
                this.$root.$emit('refreshTable', 'refreshTable')
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been inserted!',
                  },
                })
            } else {
              this.submitButtonDisabled = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: `${response.data.message}`,
                },
              })
            }
            }).catch(error => {
              console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
            })
        }
      })
    },
    validateEmail() {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.isValidEmail = regex.test(this.Email)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
