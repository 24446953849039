<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Role</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roles"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
    data() {
        return {
          roles: [],
        }
    },
    mounted() {
        this.getRoles()
    },
    methods: {
        getRoles() {
const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
            axios
                .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ROLES}`, { headers })
                .then(response => {
                  const rolesJoin = response.data.data.map(elem => (
                    { label: elem.role_name, value: elem.role_id }
                  ))
                  this.roles = rolesJoin
                })
                .catch(err => {
                    console.log(err)
                })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
